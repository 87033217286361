import React from "react";
import theme from "theme";
import { Theme, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Владимир Алексеев – digital консультант
			</title>
			<meta name={"description"} content={"Консультант в сферах: веб-разработка, мобильная разработка, интернет-маркетинг"} />
			<meta property={"og:title"} content={"Владимир Алексеев – digital консультант"} />
			<meta property={"og:description"} content={"Консультант в сферах: веб-разработка, мобильная разработка, интернет-маркетинг"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/opengraph.jpg?v=2021-04-07T15:53:43.892Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/favicon-32x32.png?v=2021-04-05T19:13:11.302Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/apple-touch-icon.png?v=2021-04-05T19:13:28.968Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/apple-touch-icon.png?v=2021-04-05T19:13:28.968Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/apple-touch-icon.png?v=2021-04-05T19:13:28.968Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/apple-touch-icon.png?v=2021-04-05T19:13:28.968Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/mstile-150x150.png?v=2021-04-05T19:13:48.534Z"} />
			<meta name={"msapplication-TileColor"} content={"#000000"} />
		</Helmet>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script place={"endOfHead"} rawKey={"606da4d00a6c172eb8a39865"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-T56RZ9Z');"}
			</script>
		</RawHtml>
	</Theme>;
});